<template>
    <el-dialog width="600px" top="40px" title="Kommentarer" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false" @open="open">
        <div v-loading="$waiting.is('modal.loading')">
            <div class="mb-8" v-for="comment in commentsData" :key="comment.commentId">
                <div class="flex px-2 justify-between">
                    <p>
                        {{ comment.individualName }}
                    </p>
                    <p>
                        {{ formatDate(comment.createdDate) }}
                    </p>
                </div>
                <p class="p-4 rounded-lg bg-gray-100">
                    {{ comment.text }}
                </p>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="primary">Stäng</el-button>
        </span>
    </el-dialog>
</template>
<script>
import moment from "moment";
import Api from "../att_atgarda.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        sourceId: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            commentsData: [],
        };
    },

    methods: {
        open() {
            this.getAllComments();
        },

        async getAllComments() {
            this.$waiting.start("modal.loading");
            this.commentsData = await Api.getCommentsBySourceId(this.sourceId);
            this.$waiting.end("modal.loading");
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD HH:mm");
        },

        closeModal() {
            this.$emit("close");
        },
    },
};
</script>
